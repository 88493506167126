import { EOrientation, ERelativePosition } from '@hyperclap/ui';
import React from 'react';
import { useMatch } from 'react-router-dom';

import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp } from '@hooks';

import s from './SettingsSubmenu.scss';

interface ISettingsSubmenuProps extends TApp {}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.submenuItem,
    submenuClassName: s.submenu,
    useActiveBorder: true,
    activeBorderPosition: ERelativePosition.LEFT,
};

export const SettingsSubmenu = (props: ISettingsSubmenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        translation: {
            t,
        },
    } = props;

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/settings/common'),
            caption: t('app.main-menu.settings.submenu.common'),
            tag: '/settings/common',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/settings/tts'),
            caption: t('app.main-menu.settings.submenu.tts'),
            tag: '/settings/tts',
            ...defaultMenuItemProps,
        },
    ];

    const onMenuItemClick = (tag?: string) => {
        if (tag) {
            goToPath(tag);
        }
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    return (
        <Menu orientation={EOrientation.VERTICAL}>
            {menuItems}
        </Menu>
    );
};
