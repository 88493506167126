import { Column } from '@hyperclap/ui';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { TApp } from '@hooks';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    return (
        <Column padding={20} grow className={s.view}>
            <Outlet context={props}/>
        </Column>
    );
};
