import { ELogLevel } from '@hyperclap/ui';

/** Hosts URLs are needed for the client application */
interface IApplicationHostsConfig {
    /** Main application host URL */
    appHost: string;

    /** API host URL */
    apiHost: string;

    ttsProxyApiHost: string;
}

interface ILoggingConfig {
    level: ELogLevel
}

interface IDevelopmentModeConfig {
    disableStrictMode?: boolean;
}

/** Configuration object structure */
export interface IConfig {
    developmentMode?: IDevelopmentModeConfig;
    hosts: IApplicationHostsConfig;
    logging: ILoggingConfig;
}
