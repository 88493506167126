import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { TApp } from '@hooks';

import { Desktop, Mobile } from './layouts';

export const Tts = () => {
    const app = useOutletContext<TApp>();

    return app.deviceInfo.isMobileLayoutRecommended
        ? <Mobile {...app}/>
        : <Desktop {...app}/>;
};
