import {
    Column,
    EColumnAlignment,
    EComponentColor,
    EComponentSize,
    Icon, ITextFieldAction, ITextFieldMethods,
    Label, Row,
    TextField,
    useClickOutside,
    useLogger,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { IconInfoLine, IconXLine } from '@assets/images/svg';
import { IVoiceDescriptor } from '@typings';

import s from './VoiceDescriptor.scss';

interface IVoiceDescriptorProps {
    descriptor: IVoiceDescriptor;
    disabled?: boolean;
    onRemoveClick: (descriptor: IVoiceDescriptor) => void;
    onChanged?: (descriptor: IVoiceDescriptor) => void;
}

export const VoiceDescriptor = (props: IVoiceDescriptorProps) => {
    const {
        descriptor,
        disabled,
        onRemoveClick,
        onChanged = noop,
    } = props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const logger = useLogger({ target: VoiceDescriptor.name, showTimestamp: true });

    const me = useRef<HTMLDivElement>(null);

    const [isInfoVisible, setInfoVisible] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [name, setName] = useState<string>(descriptor.name || descriptor.voice);

    const textFieldMethods = useRef<ITextFieldMethods>(null);

    const onTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === 'enter') {
            onChanged({ ...descriptor, name });
        }

        setIsInEditMode(false);
    };

    useClickOutside({ controlledElementRef: me, handler: () => {
        setInfoVisible(false);
    } });

    useEffect(() => {
        if (isInEditMode) {
            textFieldMethods.current?.set(name);
            textFieldMethods.current?.focus();
        }
    }, [isInEditMode]);

    return (
        <div
            ref={me}
            className={cn(
                s.voiceDescriptor,
                {
                    [s.voiceDescriptorDisabled]: disabled,
                },
            )}
        >
            { !isInEditMode
                ? <Label
                    caption={name || descriptor.name || descriptor.voice}
                    size={EComponentSize.MEDIUM}
                    useBoldFont
                    onClick={() => setIsInEditMode(true)}
                />
                : <TextField
                    actions={[
                        { name: 'enter', keyboardKeys: ['Enter'], hidden: true },
                        { name: 'escape', keyboardKeys: ['Escape'], hidden: true },
                    ]}
                    onChanged={(val) => setName(val)}
                    defaultValue={name}
                    wrapperClassName={s.voiceDescriptorTextField}
                    className={s.voiceDescriptorInput}
                    methodsRef={textFieldMethods}
                    onAction={onTextFieldAction}
                />
            }
            <Column grow/>
            <Icon
                icon={<IconInfoLine/>}
                color={EComponentColor.SUCCESS}
                className={s.iconPlay}
                size={EComponentSize.SMALL}
                onClick={() => setInfoVisible((prev) => !prev)}
            />
            <Icon
                icon={<IconXLine/>}
                color={EComponentColor.DANGER}
                className={s.iconX}
                size={EComponentSize.SMALL}
                onClick={() => onRemoveClick(descriptor)}
            />
            <Column
                alignment={EColumnAlignment.BEGIN}
                className={cn(
                    s.info,
                    {
                        [s.infoVisible]: isInfoVisible,
                    },
                )}
            >
                <Row columnGap={5}>
                    <Label caption={'Имя:'} size={EComponentSize.SMALL} muted useBoldFont/>
                    <Label caption={descriptor.name} size={EComponentSize.SMALL} />
                </Row>
                <Row columnGap={5}>
                    <Label caption={'Голос:'} size={EComponentSize.SMALL} muted useBoldFont/>
                    <Label caption={descriptor.voice} size={EComponentSize.SMALL} />
                </Row>
                <Row columnGap={5}>
                    <Label caption={'Провайдер:'} size={EComponentSize.SMALL} muted useBoldFont/>
                    <Label caption={descriptor.provider} size={EComponentSize.SMALL} />
                </Row>
            </Column>
        </div>
    );
};
