import { IVoice } from '@typings';

import { baseApi } from './base.api';

export const providersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            listProviders: build.query<Array<string>, void>({
                query: () => ({
                    url: 'tts/providers',
                    method: 'GET',
                }),
            }),
            getProviderVoices: build.query<Array<IVoice>, string>({
                query: (providerName: string) => ({
                    url: `tts/${providerName}/voices`,
                    method: 'GET',
                }),
            }),
        }),
    });
