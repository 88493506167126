import { Column, EComponentSize, Label, Row } from '@hyperclap/ui';
import React from 'react';
import { TooltipProps } from 'recharts';

import { DATE_FORMAT_LOCALE, getWeek, getWeekYear, timestampToMonth, timestampToShortDate } from '@common';
import { StatisticsMode } from '@hooks';

import s from './CustomTooltip.scss';

interface ICustomTooltipProps extends TooltipProps<number, string> {
    language: string;
    mode: StatisticsMode;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
    const {
        active,
        payload,
        label,
        mode,
    } = props;

    const formatter = new Intl.NumberFormat(DATE_FORMAT_LOCALE, {
        style: 'decimal',
        maximumFractionDigits: 2,
    });

    const formatXTick = (timestamp: number) => {
        switch (mode) {
            case StatisticsMode.DAILY:
                return timestampToShortDate(timestamp);
            case StatisticsMode.WEEKLY:
                return getWeek(timestamp) + ' / ' + getWeekYear(timestamp);
            case StatisticsMode.MONTHLY:
                return timestampToMonth(timestamp);
        }
    };

    return active
        ? (
            <Column padding={10} rowGap={5} className={s.customTooltip}>
                <Row>
                    <Label padding={0} caption={formatXTick(label)} size={EComponentSize.SMALL} useBoldFont/>
                </Row>
                {payload?.map((item, idx) => (
                    <Row key={idx}>
                        {`${item.name} ${formatter.format(item?.value ?? 0)}`}
                    </Row>
                ))}
            </Column>
        )
        : null;
};
