import { ICommonMetrics } from '@typings';

import { baseApi } from './base.api';

export const statisticsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Statistics_DailyStatsPages',
            'Statistics_CurrentDailyStats',
            'Statistics_WeeklyStatsPages',
            'Statistics_CurrentWeeklyStats',
            'Statistics_MonthlyStatsPages',
            'Statistics_CurrentMonthlyStats',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadDailyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/daily/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_DailyStatsPages'],
            }),
            getCurrentDayStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/daily/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentDailyStats'],
            }),
            loadWeeklyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/weekly/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_WeeklyStatsPages'],
            }),
            getCurrentWeekStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/weekly/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentWeeklyStats'],
            }),
            loadMonthlyStatsPage: build.query<ICommonMetrics[], number>({
                query: (page: number) => ({
                    url: `stats/monthly/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_MonthlyStatsPages'],
            }),
            getCurrentMonthStats: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/monthly/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentMonthlyStats'],
            }),
            runFullRebuildStatistics: build.query<ICommonMetrics, void>({
                query: () => ({
                    url: `stats/full-rebuild`,
                    method: 'GET',
                }),
            }),
        }),
    });
