import {
    EComponentSize,
    ERowJustification,
    Icon,
    ITextFieldAction,
    ITextFieldMethods,
    Label,
    Row,
    TextField,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { IconXLine } from '@assets/images/svg';
import { IVoicesCategory } from '@typings';

import s from './VoiceCategory.scss';

interface IVoiceCategoryProps {
    active?: boolean;
    category: IVoicesCategory;
    editMode: boolean;
    hideDeleteButton?: boolean;
    onClick?: (category: IVoicesCategory) => void;
    onDeleteClick?: (id: string) => void;
    onApplyEdit?: (name: string) => void;
    onCancelEdit?: () => void;
}

export const VoiceCategory = (props: IVoiceCategoryProps) => {
    const {
        active,
        category,
        editMode,
        hideDeleteButton,
        onClick = noop,
        onDeleteClick = noop,
        onApplyEdit = noop,
        onCancelEdit = noop,
    } = props;

    const [isEditMode, setIsEditMode] = useState(false);
    const [name, setName] = useState(category.name);

    const textFieldMethods = useRef<ITextFieldMethods>(null);

    const onTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === 'escape') {
            onCancelEdit();
        } else if (action.name === 'enter') {
            onApplyEdit(name);
        }

        setIsEditMode(false);
    };

    const onNameClick = () => {
        if (active) {
            setIsEditMode(true);
        }

        onClick(category);
    };

    useEffect(() => {
        setIsEditMode(editMode);
    }, [editMode]);

    useEffect(() => {
        if (isEditMode) textFieldMethods.current?.focus();
    }, [isEditMode]);

    useEffect(() => {
        if (!active) {
            setIsEditMode(false);
        } else if (active && editMode) {
            setIsEditMode(true);
        }
    }, [active]);

    return (
        <Row
            padding={{ top: 5, right: 15, bottom: 5, left: 15 }}
            justification={ERowJustification.SPACE_BETWEEN}
            className={cn(
                s.voiceCategory,
                {
                    [s.voiceCategoryActive]: active,
                },
            )}
        >
            { isEditMode
                ? <TextField
                    actions={[
                        { name: 'enter', keyboardKeys: ['Enter'], hidden: true },
                        { name: 'escape', keyboardKeys: ['Escape'], hidden: true },
                    ]}
                    onAction={onTextFieldAction}
                    onChanged={setName}
                    defaultValue={name}
                    wrapperClassName={s.voiceCategoryTextField}
                    className={s.voiceCategoryInput}
                    methodsRef={textFieldMethods}
                />
                : <Label
                    caption={category.name}
                    size={EComponentSize.SMALL}
                    className={cn(
                        s.voiceCategoryNameLabel,
                        {
                            [s.voiceCategoryNameLabelActive]: active,
                        },
                    )}
                    onClick={onNameClick}
                />
            }
            { !hideDeleteButton &&
                <Icon icon={<IconXLine/>} size={EComponentSize.EXTRA_SMALL} onClick={() => onDeleteClick(category.id)} />
            }
        </Row>
    );
};
