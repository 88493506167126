import { Button, Column, EComponentColor } from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        statistics: {
            runFullRebuildQuery,
        },
    } = props;

    return (
        <Column padding={20} grow className={s.view}>
            {/* <UnderConstruction /> */}
            <Button width={180} color={EComponentColor.INFO} caption={'Full Stats Rebuild'} onClick={() => runFullRebuildQuery()}/>
        </Column>
    );
};
