import {
    Button,
    Column,
    Divider,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Label,
    Row,
    ScrollableArea,
    Selector,
    Switch,
} from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';

import { StatisticsMode, TApp } from '@hooks';
import { ICommonMetrics } from '@typings';

import { StatisticsRecord } from './components';
import { StatisticsChart } from './components/StatisticsChart';
import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        statistics: {
            currentDayStats,
            currentWeekStats,
            currentMonthStats,
            dailyStatistic,
            weeklyStatistic,
            monthlyStatistic,
            isDailyEndReached,
            isWeeklyEndReached,
            isMonthlyEndReached,
            mode,
            setMode,
            loadNextDailyPage,
            loadNextWeeklyPage,
            loadNextMonthlyPage,
        },
        translation: {
            t: translate,
        },
    } = props;

    const [isDiagramShown, setIsDiagramShown] = useState(false);
    const [statisticsData, setStatisticsData] = useState<Array<ICommonMetrics>>([]);

    const t = (text: string) => translate(`app.statistics.${text}`);

    const rows = mode === StatisticsMode.DAILY
        ? dailyStatistic?.map((r, idx) => (
            <StatisticsRecord statisticsMode={mode} data={r} key={idx}/>
        ))
        : mode === StatisticsMode.WEEKLY
            ? weeklyStatistic?.map((r, idx) => (
                <StatisticsRecord statisticsMode={mode} data={r} key={idx}/>
            ))
            : monthlyStatistic?.map((r, idx) => (
                <StatisticsRecord statisticsMode={mode} data={r} key={idx}/>
            ));

    const PERIODS = [
        { caption: t('periods.day'), value: StatisticsMode.DAILY },
        { caption: t('periods.week'), value: StatisticsMode.WEEKLY },
        { caption: t('periods.month'), value: StatisticsMode.MONTHLY },
    ];

    const onNextPageClick = () => {
        switch (mode) {
            case StatisticsMode.DAILY: {
                loadNextDailyPage();
                break;
            }

            case StatisticsMode.WEEKLY: {
                loadNextWeeklyPage();
                break;
            }

            case StatisticsMode.MONTHLY: {
                loadNextMonthlyPage();
                break;
            }
        }
    };

    useEffect(() => {
        switch (mode) {
            case StatisticsMode.DAILY: {
                setStatisticsData([...dailyStatistic].reverse());
                break;
            }

            case StatisticsMode.WEEKLY: {
                setStatisticsData([...weeklyStatistic].reverse());
                break;
            }

            case StatisticsMode.MONTHLY: {
                setStatisticsData([...monthlyStatistic].reverse());
                break;
            }
        }
    }, [mode, dailyStatistic, weeklyStatistic, monthlyStatistic]);

    return (
        <Column grow padding={10} className={s.view}>
            <Row columnGap={15} justification={ERowJustification.SPACE_BETWEEN}>
                <Column>
                    <Label caption={t('title')} size={EComponentSize.MEDIUM} useBoldFont/>
                </Column>
                <Column grow>
                    <Switch
                        caption={'Показать графики'}
                        color={EComponentColor.ACCENT}
                        onChange={(value) => setIsDiagramShown(value)}
                    />
                </Column>
                <Column>
                    {t('period')}
                </Column>
                <Column>
                    <Selector
                        valuesList={PERIODS}
                        defaultSelectionIndex={mode}
                        hideListOnSelect
                        className={s.selector}
                        onSelectionChange={(item) => setMode(item.value)}/>
                </Column>
                <Column>
                    <Button
                        caption={t('load-more')}
                        disabled={
                            (mode === StatisticsMode.DAILY && isDailyEndReached) ||
                            (mode === StatisticsMode.MONTHLY && isMonthlyEndReached) ||
                            (mode === StatisticsMode.WEEKLY && isWeeklyEndReached)
                        }
                        onClick={onNextPageClick}
                    />
                </Column>
            </Row>

            { isDiagramShown &&
                <>
                    <Divider/>

                    <Row columnGap={15} grow>
                        <StatisticsChart data={statisticsData} mode={mode} />
                    </Row>
                </>
            }

            { !isDiagramShown &&
                <>
                    <Divider/>

                    <Row grow alignment={ERowAlignment.TOP}>
                        <ScrollableArea className={s.scrollableContainer} contentClassName={s.scrollableContainerContent}>
                            <table className={s.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            {mode === StatisticsMode.DAILY ? 'Date' : mode === StatisticsMode.WEEKLY ? 'Week' : 'Month'}
                                        </th>
                                        <th>
                                            {'New Users'}
                                        </th>
                                        <th>
                                            {'Total Users'}
                                        </th>
                                        <th>
                                            {'New Supporters'}
                                        </th>
                                        <th>
                                            {'Total Supporters'}
                                        </th>
                                        <th>
                                            {'New Payers'}
                                        </th>
                                        <th>
                                            {'Total Payers'}
                                        </th>
                                        <th>
                                            {'DAU'}
                                        </th>
                                        <th>
                                            {'Payers'}
                                        </th>
                                        <th>
                                            {'Bonus Hunters'}
                                        </th>
                                        <th>
                                            {'Senders'}
                                        </th>
                                        <th>
                                            {'Payers %'}
                                        </th>
                                        <th>
                                            {'Bonus Hunters %'}
                                        </th>
                                        <th>
                                            {'Senders %'}
                                        </th>
                                        <th>
                                            {'New Streamers'}
                                        </th>
                                        <th>
                                            {'Total Streamers'}
                                        </th>
                                        <th>
                                            {'DOS'}
                                        </th>
                                        <th>
                                            {'DAS'}
                                        </th>
                                        <th>
                                            {'DES'}
                                        </th>
                                        <th>
                                            {'DAS%'}
                                        </th>
                                        <th>
                                            {'Sticker Open'}
                                        </th>
                                        <th>
                                            {'Sticker Sent'}
                                        </th>
                                        <th>
                                            {'Sent Ratio'}
                                        </th>
                                        <th>
                                            {'Audio'}
                                        </th>
                                        <th>
                                            {'FS'}
                                        </th>
                                        <th>
                                            {'MC'}
                                        </th>
                                        <th>
                                            {'Audio %'}
                                        </th>
                                        <th>
                                            {'FS %'}
                                        </th>
                                        <th>
                                            {'MC %'}
                                        </th>
                                        <th>
                                            {'SPS'}
                                        </th>
                                        <th>
                                            {'MP'}
                                        </th>
                                        <th>
                                            {'Purchase'}
                                        </th>
                                        <th>
                                            {'Bonus'}
                                        </th>
                                        <th>
                                            {'Spent'}
                                        </th>
                                        <th>
                                            {'Spent SS%'}
                                        </th>
                                        <th>
                                            {'Spent FS%'}
                                        </th>
                                        <th>
                                            {'Spent MC%'}
                                        </th>
                                        <th>
                                            {'ARPAS'}
                                        </th>
                                        <th>
                                            {'ARPPU'}
                                        </th>
                                        <th>
                                            {'ARPSS'}
                                        </th>
                                        <th>
                                            {'Revenue'}
                                        </th>
                                        <th>
                                            {'Profit'}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mode === StatisticsMode.DAILY && currentDayStats &&
                                        <StatisticsRecord statisticsMode={mode} data={currentDayStats} key={-1}/>
                                    }
                                    {mode === StatisticsMode.WEEKLY && currentWeekStats &&
                                        <StatisticsRecord statisticsMode={mode} data={currentWeekStats} key={-1}/>
                                    }
                                    {mode === StatisticsMode.MONTHLY && currentMonthStats &&
                                        <StatisticsRecord statisticsMode={mode} data={currentMonthStats} key={-1}/>
                                    }

                                    {rows}
                                </tbody>
                            </table>
                        </ScrollableArea>
                    </Row>
                </>
            }
        </Column>
    );
};
