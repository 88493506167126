import { Column, EComponentColor, EComponentSize, Icon, Label, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconPlayLine, IconPlusLine } from '@assets/images/svg';
import { IVoice } from '@typings';

import s from './Voice.scss';

interface IVoiceProps {
    voice: IVoice;
    disabled?: boolean;
    onPlayClick: (voice: IVoice) => void;
    onPlusClick: (voice: IVoice) => void;
}

export const Voice = (props: IVoiceProps) => {
    const {
        voice,
        disabled,
        onPlayClick,
        onPlusClick,
    } = props;

    return (
        <Row
            columnGap={5}
            padding={{ left: 15, right: 15 }}
            className={cn(
                s.voice,
                {
                    [s.voiceDisabled]: disabled,
                },
            )}
        >
            <Label
                caption={voice.name}
                size={EComponentSize.MEDIUM}
                useBoldFont
                onClick={() => alert(JSON.stringify(voice))}
            />
            <Column grow/>
            <Icon
                icon={<IconPlayLine/>}
                color={EComponentColor.SUCCESS}
                className={s.iconPlay}
                size={EComponentSize.SMALL}
                onClick={() => onPlayClick(voice)}
            />
            <Icon
                icon={<IconPlusLine/>}
                color={EComponentColor.ACCENT}
                className={s.iconPlus}
                size={EComponentSize.SMALL}
                onClick={() => onPlusClick(voice)}
            />
        </Row>
    );
};
