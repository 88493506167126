import { Column } from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';

interface IMobileProps extends TApp {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Mobile = (props: IMobileProps) => {
    return (
        <Column>Mobile layout is under construction</Column>
    );
};
