import { Button } from '@hyperclap/ui';
import React from 'react';

import s from './ToolButton.scss';

interface IToolButtonProps extends Parameters<typeof Button> {}

export const ToolButton = (props: IToolButtonProps[0]) => {
    return (
        <Button
            {...props}
            className={s.toolButton}
        />
    );
};
