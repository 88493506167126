import React from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { getWeek, getWeekYear, timestampToMonth, timestampToShortDate } from '@common';
import { StatisticsMode } from '@hooks';
import { ICommonMetrics } from '@typings';

import s from './StatisticsChart.scss';
import { CustomTooltip } from '../CustomTooltip';


interface IStatisticsChartProps {
    data: Array<ICommonMetrics>;
    mode: StatisticsMode;
}

export const StatisticsChart = (props: IStatisticsChartProps) => {
    const {
        data,
        mode,
    } = props;

    const { i18n } = useTranslation();

    const formatXTick = (timestamp: number) => {
        switch (mode) {
            case StatisticsMode.DAILY:
                return timestampToShortDate(timestamp);
            case StatisticsMode.WEEKLY:
                return getWeek(timestamp) + ' / ' + getWeekYear(timestamp);
            case StatisticsMode.MONTHLY:
                return timestampToMonth(timestamp);
        }
    };

    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <LineChart
                width={500}
                height={200}
                data={data}
            >
                <CartesianGrid strokeDasharray={'1 3'} className={s.chartGrid}/>
                <XAxis dataKey={'timestamp'} tickFormatter={formatXTick} fontSize={12} />
                <Tooltip
                    content={<CustomTooltip mode={mode} language={i18n.language}/>}
                    wrapperClassName={s.chartTooltipWrapper}
                    animationDuration={100}
                    cursor={false}
                    labelClassName={s.chartTooltipWrapperLabel}
                />
                <Legend />
                <Line type="monotone" name={'Revenue'} dataKey="revenue" stroke="#cc6600" animationDuration={500} activeDot={{ r: 8 }} />
                <Line type="monotone" name={'Profit'} dataKey="profit" stroke="#00cc66" animationDuration={500} activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};
