import { useDeviceInfo, useModals } from '@hyperclap/ui';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { useLogin } from './login';
import { useNavigator } from './navigator';
import { useSettings } from './settings';
import { useStatistics } from './statistics';
import { useCurrentUser } from './users';
import { useNotifications } from '../contexts';

export const useApp = () => {
    const currentUser = useCurrentUser();
    const deviceInfo = useDeviceInfo();
    const login = useLogin();
    const modals = useModals();
    const navigation = useNavigator();
    const notifications = useNotifications();
    const settings = useSettings();
    const statistics = useStatistics();
    const translation = useTranslation();

    return {
        currentUser,
        deviceInfo,
        login,
        modals,
        navigation,
        notifications,
        settings,
        statistics,
        translation,
    };
};

export type TApp = ReturnType<typeof useApp>;

export const useAppContext = () => useOutletContext<TApp>();
