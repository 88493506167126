import { useEffect, useState } from 'react';

import { api } from '@store';
import { ICommonMetrics } from '@typings';

export enum StatisticsMode {
    DAILY,
    WEEKLY,
    MONTHLY,
}

export const useStatistics = () => {
    const {
        statistics: {
            useLazyLoadDailyStatsPageQuery,
            useGetCurrentDayStatsQuery,
            useLazyLoadWeeklyStatsPageQuery,
            useGetCurrentWeekStatsQuery,
            useLazyLoadMonthlyStatsPageQuery,
            useGetCurrentMonthStatsQuery,
            useLazyRunFullRebuildStatisticsQuery,
        },
    } = api;

    const [mode, setMode] = useState(StatisticsMode.DAILY);
    const [dailyPage, setDailyPage] = useState(1);
    const [weeklyPage, setWeeklyPage] = useState(1);
    const [monthlyPage, setMonthlyPage] = useState(1);
    const [isDailyEndReached, setIsDailyEndReached] = useState(false);
    const [isWeeklyEndReached, setIsWeeklyEndReached] = useState(false);
    const [isMonthlyEndReached, setIsMonthlyEndReached] = useState(false);
    const [dailyStatistic, setDailyStatistic] = useState<ICommonMetrics[]>([]);
    const [weeklyStatistic, setWeeklyStatistic] = useState<ICommonMetrics[]>([]);
    const [monthlyStatistic, setMonthlyStatistic] = useState<ICommonMetrics[]>([]);

    const {
        data: currentDayStats,
    } = useGetCurrentDayStatsQuery();

    const {
        data: currentWeekStats,
    } = useGetCurrentWeekStatsQuery();

    const {
        data: currentMonthStats,
    } = useGetCurrentMonthStatsQuery();

    const [
        loadDailyPage,
        {
            data: loadedDailyPage,
            isLoading: isDailyStatisticLoading,
        },
    ] = useLazyLoadDailyStatsPageQuery();

    const [
        loadWeeklyPage,
        {
            data: loadedWeeklyPage,
            isLoading: isWeeklyStatisticLoading,
        },
    ] = useLazyLoadWeeklyStatsPageQuery();

    const [
        loadMonthlyPage,
        {
            data: loadedMonthlyPage,
            isLoading: isMonthlyStatisticLoading,
        },
    ] = useLazyLoadMonthlyStatsPageQuery();

    const [
        runFullRebuildQuery,
    ] = useLazyRunFullRebuildStatisticsQuery();

    const loadNextDailyPage = () => {
        setDailyPage(dailyPage + 1);
    };

    const loadNextWeeklyPage = () => {
        setWeeklyPage(weeklyPage + 1);
    };

    const loadNextMonthlyPage = () => {
        setMonthlyPage(monthlyPage + 1);
    };

    useEffect(() => {
        loadDailyPage(dailyPage);
        loadWeeklyPage(dailyPage);
        loadMonthlyPage(monthlyPage);
    }, []);

    useEffect(() => {
        loadDailyPage(dailyPage);
    }, [dailyPage]);

    useEffect(() => {
        loadWeeklyPage(weeklyPage);
    }, [weeklyPage]);

    useEffect(() => {
        loadMonthlyPage(monthlyPage);
    }, [monthlyPage]);

    useEffect(() => {
        if (loadedDailyPage && !loadedDailyPage.length) {
            setIsDailyEndReached(true);
        }

        setDailyStatistic((prev) => [...prev].concat(loadedDailyPage ?? []));
    }, [loadedDailyPage]);

    useEffect(() => {
        if (loadedWeeklyPage && !loadedWeeklyPage.length) {
            setIsWeeklyEndReached(true);
        }

        setWeeklyStatistic((prev) => [...prev].concat(loadedWeeklyPage ?? []));
    }, [loadedWeeklyPage]);

    useEffect(() => {
        if (loadedMonthlyPage && !loadedMonthlyPage.length) {
            setIsMonthlyEndReached(true);
        }

        setMonthlyStatistic((prev) => [...prev].concat(loadedMonthlyPage ?? []));
    }, [loadedMonthlyPage]);

    return {
        mode,
        currentDayStats,
        currentWeekStats,
        currentMonthStats,
        dailyStatistic,
        weeklyStatistic,
        monthlyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        isWeeklyStatisticLoading,
        isWeeklyEndReached,
        isMonthlyStatisticLoading,
        isMonthlyEndReached,
        setMode,
        loadNextDailyPage,
        loadNextWeeklyPage,
        loadNextMonthlyPage,
        runFullRebuildQuery,
    };
};
